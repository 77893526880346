
import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddPurchaseDispatchOrderModal from "@/components/modals/forms/AddPurchaseDispatchOrderModal.vue";
import AddProductListSalesEnquiry from "@/components/modals/forms/AddProductListSalesEnquiry.vue";
// import Datatable from "@/components/kt-datatable/KTDatatable.vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import customers from "@/core/data/customers";
import roc_list, { business_types } from "@/core/data/genericData";
import { ICustomer } from "@/core/data/customers";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { array, string } from "yup/lib/locale";
import JwtService from "@/core/services/JwtService";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import { Modal } from "bootstrap";
import MixedWidget10 from "@/components/widgets/mixed/Widget10.vue";
import moment from "moment";

export default defineComponent({
  name: "customers-listing",
  components: {
    ExportCustomerModal,
    AddPurchaseDispatchOrderModal,
    AddProductListSalesEnquiry,
    MixedWidget10,
    // Datatable,
  },

  setup() {
    const count = ref(0);
    const store = useStore();
    const checkedCompany = ref([]);
    const loadingData = ref<boolean>(true);
    const searchTearm = ref("");
    const router = useRouter();
    const listVisible = ref<boolean>(true);

    interface WICompanies {
      tn_id : number;
      tn_no: string;
      tn_date: string;
      tn_ref_for_vc: string;
      pdo_no: string;
      consignment: Record<string, unknown>[]
      transport: string;
      contact_detail: Record<string, string | number>[];
      transporter_vehicle_no: string;
      transporter_driver_name: string;
      transporter_driver_contact_no: string
      weight: string,
      no_of_items: number,
      fare_amount: number,
      status: string
    }

    const formData = ref({
      startDate: "",
      endDate: "",
      tn_no:''
    });

    var paginationData = ref({});

    paginationData.value = {
      start: 0,
      end: 0,
      total: 0,
      perPage: 5,
      activePage: 1,
      totPage: 1,
      pageRange: [1],
    };

    const refreshData = () => {
      paginationData.value["activePage"] = 1;
      getCompanyList(searchTearm.value);
    };

    const changePageChange = (page) => {
      paginationData.value["activePage"] = page;
      getCompanyList(searchTearm.value);
    };

    var tableData = ref<Array<WICompanies>>([]);

    const getCompanyList = async (data) => {
      loadingData.value = true;
      var user = JSON.parse(JwtService.getToken());
      let startDate = '';
      let endDate = '';
      if(formData.value['startDate']?.length){
        startDate = moment(formData?.value?.startDate[0])?.format("YYYY-MM-DD")
        endDate = moment(formData?.value?.startDate[1])?.format("YYYY-MM-DD")
      }

      try {
        var values = {
          user_id : user.user_id,
          page: parseInt(paginationData.value["activePage"]),
          records_per_page: parseInt(paginationData.value["perPage"]),
          tn_no: formData.value?.tn_no,
          start_date: startDate,
          end_date: endDate
        };
        console.log(values);
        await store
          .dispatch(
            ActionsFi.CUST_GET_LOGISTIC_MANAGEMENT_LIST,
            values
          )
          .then((data) => {
            debugger;
            console.log("total data:" + JSON.stringify(data));

            debugger;
            tableData.value = [];
            const totalCount = data?.data?.total_records;
            // set pagination
            paginationData.value["total"] = data?.data?.total_records;
            paginationData.value["start"] = data?.data?.records_from;
            paginationData.value["end"] = data?.data?.records_upto;
            paginationData.value["activePage"] = data?.data?.page;
            paginationData.value["totPage"] = data?.data?.total_pages;
            paginationData.value["pageRange"] = [];
            for (
              let index = data?.data?.bar_range_start;
              index < data?.data?.bar_range_end;
              index++
            ) {
              paginationData.value["pageRange"].push(index);
            }
            //endpagination
            data = data?.data?.result_list;

            var resultsM = ref<Array<WICompanies>>([]);
            var status_label = "";
            var status_color = "";
            var active_label = "";
            var active_color = "";

            for (let j = 0; j < data.length; j++) {
              resultsM.value = Array({
                tn_id: data[j]['tn_id'],
                tn_no: data[j]['tn_no'],
                tn_date: data[j]['tn_date'],
                tn_ref_for_vc: data[j]['tn_ref_for_vc'],
                pdo_no: data[j]['pdo_no'],
                consignment: data[j]['consignment'],
                transport: data[j]['transport'],
                contact_detail: data[j]['contact_detail'],
                transporter_vehicle_no: data[j]['transporter_vehicle_no'],
                transporter_driver_name:  data[j]['transporter_driver_name'],
                transporter_driver_contact_no: data[j]['transporter_driver_contact_no'],
                weight: data[j]['weight'],
                no_of_items: data[j]['no_of_items'],
                fare_amount: data[j]['fare_amount'],
                status: data[j]['status']
              });
              console.log("resultsm:" + JSON.stringify(resultsM.value));
              tableData.value.splice(
                j,
                resultsM.value.length,
                ...resultsM.value
              );
            }

            loadingData.value = false;
          })
          .catch(({ response }) => {
            tableData.value = [];
            loadingData.value = false;
          });
      } catch (e) {
        console.log(e);
      }
    };

    // onUpdated(() => {          });

    // var tableData = ref<Array<WICompanies>>([]);
    const initCustomers = ref<Array<WICompanies>>([]);

    onMounted(async () => {
      await getCompanyList(searchTearm.value);
      setCurrentPageBreadcrumbs("Transportation Note", []);
      initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
    });

    const deleteFewCustomers = () => {
      checkedCompany.value.forEach((item) => {
        deleteCustomer(item);
      });
      checkedCompany.value.length = 0;
    };

    const deleteCustomer = (id) => {
      // for (let i = 0; i < tableData.value.length; i++) {
      //   if (tableData.value[i].company_id === id) {
      //     tableData.value.splice(i, 1);
      //   }
      // }
    };

    const product_add_modal = (sales_enquiry_id) => {
      const modal = new Modal(
        document.getElementById("kt_modal_add_product_sales_enquiry")
      );
      modal.show();
      (document.querySelector("#sales_enquiry_id") as HTMLInputElement).value =
        sales_enquiry_id;
    };

    const search = ref<string>("");
    const searchItems = () => {
      searchTearm.value = search.value;
      getCompanyList(search.value);
      console.log(search.value);
      // tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
      // if (search.value !== "") {
      //   let results: Array<WICompanies> = [];
      //   for (let j = 0; j < tableData.value.length; j++) {
      //     if (searchingFunc(tableData.value[j], search.value)) {
      //       results.push(tableData.value[j]);
      //     }
      //   }
      //   tableData.value.splice(0, tableData.value.length, ...results);
      // }
    };

    function showList() {
      listVisible.value = true;
      console.log("list visible.." + showList);
    }
    function hideList() {
      listVisible.value = false;
    }

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    return {
      tableData,
      deleteCustomer,
      search,
      searchItems,
      checkedCompany,
      deleteFewCustomers,
      count,
      getCompanyList,
      paginationData,
      changePageChange,
      refreshData,
      loadingData,
      product_add_modal,
      showList,
      listVisible,
      hideList,
      router,
      formData,
    };
  },
});
